<template>
  <b-modal
      id="modal-edit-personalization-set"
      ref="modal-edit-personalization-set"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.editPersonalizationSet.title') }}</h3>
    </template>

    <template #default>
      <div>
        <div class="mt-4 mx-5 mb-3">
          <b-form-input
              v-model="personalizationSetName"
              :placeholder="$t('modals.editPersonalizationSet.name')"
              autofocus
              debounce="500"
              @keyup.enter="enterPressed"
          >
          </b-form-input>
        </div>
        <div class="mx-5 mb-4">
          <b-form-input
              v-model="personalizationSetDescription"
              :placeholder=" $t('modals.editPersonalizationSet.description') "
              debounce="500"
              @keyup.enter="enterPressed"
          >
          </b-form-input>
        </div>
      </div>
    </template>

    <template #modal-footer>
      <b-button
          class="p-3 border-right-light-grey"
          block
          @click="cancel()"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
          :disabled="!isPersonalizationSetNameSet"
          variant="primary"
          class="p-3"
          block
          @click="editPersonalizationSet"
      >
        {{ $t('modals.editPersonalizationSet.validate') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalCreateNewPersonalizationSet',
  props: ['setName', 'setDescription'],
  data: () => ({
    personalizationSetName: null,
    personalizationSetDescription: null,
  }),
  computed: {
    isPersonalizationSetNameSet() {
      return !!this.personalizationSetName;
    },
  },
  created() {
    this.personalizationSetName = this.setName;
    this.personalizationSetDescription = this.setDescription;
  },
  mounted() {
    this.$refs['modal-edit-personalization-set'].show();
  },
  methods: {
    cancel() {
      this.$refs['modal-edit-personalization-set'].hide();
      this.$emit('close-edit-personalization-set', null);
    },
    editPersonalizationSet() {
      this.$emit('edit-personalization-set', {
        name: (this.personalizationSetName) ? this.personalizationSetName.trim() : null,
        description: (this.personalizationSetDescription)
          ? this.personalizationSetDescription.trim() : null,
      });
      this.$refs['modal-edit-personalization-set'].hide();
    },
    enterPressed() {
      if (this.personalizationSetName) {
        this.editPersonalizationSet();
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
