var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "modal-edit-personalization-set",
    attrs: {
      id: "modal-edit-personalization-set",
      "footer-class": "flex-nowrap",
      "no-close-on-backdrop": "",
      centered: ""
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function() {
          return [
            _c("h3", [
              _vm._v(_vm._s(_vm.$t("modals.editPersonalizationSet.title")))
            ])
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("div", [
              _c(
                "div",
                { staticClass: "mt-4 mx-5 mb-3" },
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: _vm.$t("modals.editPersonalizationSet.name"),
                      autofocus: "",
                      debounce: "500"
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.enterPressed.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.personalizationSetName,
                      callback: function($$v) {
                        _vm.personalizationSetName = $$v
                      },
                      expression: "personalizationSetName"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mx-5 mb-4" },
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: _vm.$t(
                        "modals.editPersonalizationSet.description"
                      ),
                      debounce: "500"
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.enterPressed.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.personalizationSetDescription,
                      callback: function($$v) {
                        _vm.personalizationSetDescription = $$v
                      },
                      expression: "personalizationSetDescription"
                    }
                  })
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function() {
          return [
            _c(
              "b-button",
              {
                staticClass: "p-3 border-right-light-grey",
                attrs: { block: "" },
                on: {
                  click: function($event) {
                    return _vm.cancel()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
            ),
            _c(
              "b-button",
              {
                staticClass: "p-3",
                attrs: {
                  disabled: !_vm.isPersonalizationSetNameSet,
                  variant: "primary",
                  block: ""
                },
                on: { click: _vm.editPersonalizationSet }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("modals.editPersonalizationSet.validate")) +
                    " "
                )
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }